<template>
  <b-card
    no-body
    class="mb-4 issuer-card"
  >
    <div class="d-flex justify-content-between py-3 px-4 issuer-card-title">
      <div class="d-flex align-items-center">
        <div
          class="mr-3"
          style="max-width: 50px;max-height: 50px;"
        >
          <cp-image
            :src="getImagePath(issuer.logo, 'logo')"
            :default-src="getImagePath('logo.png', 'logo')"
            :title="issuer.name"
          />
        </div>
        <div class="row ml-1">
          <div class="col">
            <div class="row">
              <h3 class="text-dark text-big my-0 font-weight-semibold">
                {{ issuer.name }}
              </h3>
            </div>
            <div
              class="row"
              style="margin-top: 8px; gap: 8px;"
            >
              <b-badge
                v-for="(issuerLabel, i) in (issuer.labels || []).slice(0,maxDisplayableLabels)"
                :key="i"
                variant="outline-info"
              >
                {{ issuerLabel }}
              </b-badge>
              <b-badge
                v-if="(issuer.labels || []).length>maxDisplayableLabels"
                variant="outline-info"

                :title="issuer.labels.slice(maxDisplayableLabels).join(', ')"
              >
                +{{ issuer.labels.length-maxDisplayableLabels }}
              </b-badge>
            </div>
          </div>
        </div>
      </div>

      <div v-if="isMegaAdmin">
        <b-dropdown
          right
          no-caret
          size="sm"
          variant="default icon-btn btn-round borderless"
        >
          <template slot="button-content">
            <i class="ion ion-ios-more" />
          </template>
          <b-dropdown-item
            class="bordered-bottom"
            @click="$emit('onManageLabels', issuer)"
          >
            {{ $t('component.issuerBox.issuerLabels.manageLabels') }}
          </b-dropdown-item>
          <b-dropdown-item @click="$emit('onRemoveIssuer', issuer)">
            {{ $t('common.button.delete') }}
          </b-dropdown-item>
        </b-dropdown>
      </div>
    </div>
    <hr class="m-0">
    <div class="card-body py-2">
      <div class="row">
        <div class="col">
          <small>
            {{ $t('component.issuerBox.label.fundraise') }}:
            <b-badge :variant="issuer.raisingStatus ? 'success' : 'danger'">
              {{ raisingStatusLabel }}
            </b-badge>
          </small>
        </div>
        <div class="col">
          <small>
            {{ $t('component.issuerBox.label.token') }}:
            <b-badge :variant="issuer.tokensIssued ? 'success' : 'default'">
              {{ tokensIssuedLabel }}
            </b-badge>
          </small>
        </div>
      </div>
    </div>
    <hr class="m-0">
    <b-card-body class="p-3 issuer-card-body">
      <b-table
        striped
        bordered
        :fields="fields"
        :items="getVisibleIssuers"
        class="mb-0"
      >
        <template
          slot="label"
          slot-scope="row"
        >
          <div class="td-set-width">
            {{ row.item.label }}
          </div>
        </template>
        <template
          slot="value"
          slot-scope="row"
        >
          <strong class="td-value-ellipsis">
            {{ row.value }}
          </strong>
        </template>
      </b-table>
    </b-card-body>
    <b-card-footer>
      <b-button
        variant="outline-primary"
        block
        @click="$emit('onViewIssuer', issuer)"
      >
        {{ $t('component.issuerBox.button.issuer.view') }}
      </b-button>
    </b-card-footer>
  </b-card>
</template>


<script>
import { mapState } from 'vuex';
import CpStaticPathGenerationMixin from '~/mixins/static-path-generation';
import CpCurrencyFormatter from '~/mixins/currency-formatter';
import CpImage from '~/components/common/standalone-components/cp-image';

export default {
  name: 'CpIssuerBox',
  components: {
    CpImage,
  },
  mixins: [
    CpStaticPathGenerationMixin,
    CpCurrencyFormatter,
  ],
  props: {
    issuer: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      fields: [
        {
          key: 'label',
          label: 'Column Name',
          thStyle: {
            display: 'none',
          },
        },
        {
          key: 'value',
          label: 'Column Name',
          thStyle: {
            display: 'none',
          },
          tdStyle: {
            fontWeight: 'bold',
          },
        },
      ],
      maxDisplayableLabels: 4,
    };
  },
  computed: {
    ...mapState('currentOperator', ['authorizationLevel']),
    getIssuers() {
      return [
        {
          visible: this.issuer.raisingStatus,
          label: this.$t('component.issuerBox.label.amountPledged'),
          value: this.formatCurrency(Math.round(this.issuer.amountPledged), this.issuer.mainCurrencyIdentifier, true),
        },
        {
          visible: this.issuer.raisingStatus,
          label: this.$t('component.issuerBox.label.amountFunded'),
          value: this.formatCurrency(Math.round(this.issuer.amountFunded), this.issuer.mainCurrencyIdentifier, true),
        },
        {
          visible: true,
          label: this.$t('component.issuerBox.label.registeredInvestors'),
          value: this.issuer.registeredInvestors,
        },
        {
          visible: this.issuer.tokensIssued,
          label: this.$t('component.issuerBox.label.holders'),
          value: this.issuer.tokenHoldersCount,
        },
        {
          visible: true,
          label: this.$t('component.issuerBox.label.pendingActions'),
          value: this.issuer.pendingActions,
        },
      ];
    },
    getVisibleIssuers() {
      return this.getIssuers.filter(({ visible }) => visible);
    },
    isMegaAdmin() {
      return this.authorizationLevel === 'megaadmin';
    },
    raisingStatusLabel() {
      return this.issuer.raisingStatus
        ? this.$t('component.issuerBox.status.active')
        : this.$t('component.issuerBox.status.inactive');
    },
    tokensIssuedLabel() {
      return this.issuer.tokensIssued
        ? this.$t('component.issuerBox.status.issued')
        : this.$t('component.issuerBox.status.notIssued');
    },
  },
};
</script>

<style src="~/vendor/styles/pages/users.scss" lang="scss"></style>
<style scoped lang="scss">
  .issuer-card-title {
    min-height: 96px;
  }

  .issuer-card-body {
    min-height: 243px;
  }

  .td-set-width {
    min-width: 140px;
  }

  .td-value-ellipsis {
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }

  .bordered-bottom {
    border-bottom: 1px solid #E6E7E9;
  }
</style>
