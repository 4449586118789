<template>
  <div>
    <b-modal
      ref="issuerLabelsModal"
      size="sm"
      title-icon="ion-ios-pricetag"
      modal-class="label-model-overwrite"
      ok-variant="primary"
      body-class="p-0"
      cancel-variant="default"
      no-close-on-esc
      :ok-text="$t('common.button.save')"
      :cancel-text="$t('common.button.cancel')"
      @ok="save"
    >
      <div slot="modal-title">
        <div>
          <h5 class="my-0">
            <span class="ion ion-ios-pricetag" />
            {{ $t('component.issuerLabelsModal.title.manage') }}
            {{ issuer.name }}
            {{ $t('component.issuerLabelsModal.title.labels') }}
          </h5>
        </div>

        <!-- Search -->
        <div class="py-2 px-4 my-3">
          <label class="nav-item navbar-text navbar-search-box p-0 active">
            <i class="ion ion-ios-search navbar-icon align-middle" />
            <span class="navbar-search-input pl-2">
              <b-input
                v-model="search"
                class="form-control navbar-text mx-2"
                :placeholder="$t('common.placeholder.search')"
                style="width:200px"
                @input="searchHandler"
              />
            </span>
          </label>
        </div>

        <!-- Labels -->
        <div
          style="overflow: auto"
          class="py-3 px-4 d-flex flex-column cp-labels-list"
        >
          <div v-if="loading">
            {{ $t('component.issuerLabelsModal.loading') }}
          </div>

          <div
            v-if="!loading && !labelsToShow.length"
            class="label"
          >
            {{ $t('component.issuerLabelsModal.notFound') }}
          </div>

          <b-form-checkbox
            v-for="(label, key, index) in labelsToShow"
            v-else-if="!loading && labelsToShow.length"
            :key="index"
            v-model="currentIssuerLabels"
            class="mb-1"
            :value="label"
            :aria-checked="currentIssuerLabels.indexOf(label) !== -1"
            :disabled="currentIssuerLabels.indexOf(label) === -1 && labelsDisabled"
          >
            <div class="cp-custom-label">
              <span class="label">{{ label }}</span>
              <b-btn
                variant="default edit-operator-btn btn-xs md-btn-flat"
                :title="$t('common.button.delete')"
                @click="openConfirmModal(label)"
              >
                <i class="ion ion-ios-trash" />
              </b-btn>
            </div>
          </b-form-checkbox>
        </div>
      </div>
      <!-- Create Label -->
      <div>
        <cp-input-container class="bg-light py-3 px-4">
          <div class="label-input-wrap">
            <cp-input
              v-model="newLabel"
              name="createLabel"
              :disabled="labelsDisabled"
              :class="{ 'border border-danger': createLabelError }"
              :placeholder="$t('labelModal.placeholder.labelName')"
            />
          </div>
          <div class="create-wrap">
            <span>
              <cp-button
                variant="default"
                class="button-reset"
                :disabled="labelsDisabled || loading"
                @click="createNewLabel"
              >
                <i class="ion ion-ios-add-circle-outline" />
                {{ $t('labelModal.button.label.create') }}
              </cp-button>
            </span>
          </div>
          <p class="mb-0">
            {{ createLabelError }}
          </p>
        </cp-input-container>
      </div>
    </b-modal>

    <b-modal
      ref="confirmDeleteModal"
      hide-header
      :ok-title="$t('common.button.yes')"
      :cancel-title="$t('common.button.no')"
      ok-variant="danger"
      cancel-variant="default"
      size="sm"
      @ok="deleteSelectedLabel"
      @hide="deleteLabelCanceled"
    >
      <div>
        <h2 class="confirm-delete-title">
          {{ $t('component.issuerLabelsModal.deleteLabel.title') }}
        </h2>
        <p>
          {{ $t('component.issuerLabelsModal.deleteLabel.message1') }}
          "{{ currentLabelToDelete }}".
          {{ $t('component.issuerLabelsModal.deleteLabel.message2') }}
        </p>
      </div>
    </b-modal>
  </div>
</template>

<script>
import _ from 'lodash';

import { mapActions, mapMutations, mapState } from 'vuex';
import { CpInput } from '~/components/common/standalone-components/inputs';
import cpInputContainer from '~/components/common/cpInputContainer';
import CpButton from '@/components/common/standalone-components/cp-button';

export default {
  name: 'IssuerLabelsModal',
  components: {
    cpInputContainer,
    CpInput,
    CpButton,
  },
  data() {
    return {
      loading: false,
      currentIssuerLabels: [],
      issuer: {},
      search: '',
      newLabel: '',
      createLabelError: '',
      processing: false,
      currentLabelToDelete: '',
      searchingFilters: false,
    };
  },
  computed: {
    ...mapState('issuerLabels', ['labels']),
    labelsDisabled() {
      return this.currentIssuerLabels.length >= 5;
    },
    labelsToShow() {
      if (this.searchingFilters && !this.loading) {
        return this.labels;
      }

      const issuerLabels = this.currentIssuerLabels;
      const filteredLabels = this.labels.filter(label => this.currentIssuerLabels.indexOf(label) === -1);
      return [...issuerLabels, ...filteredLabels];
    },
  },
  methods: {
    ...mapActions('issuerLabels', ['fetchIssuerLabels', 'fetchLabelsForIssuer', 'createLabelForIssuer', 'deleteLabel', 'updateIssuerLabels']),
    ...mapMutations('global', ['UPDATE_GLOBAL_ISSUER']),
    searchHandler: _.debounce(async function () { // eslint-disable-line func-names
      this.loading = true;
      if (this.search) {
        this.searchingFilters = true;
      }
      await this.fetchIssuerLabels(this.search);
      if (!this.search) {
        this.searchingFilters = false;
      }
      this.loading = false;
    }, 500),
    async deleteSelectedLabel() {
      try {
        this.loading = true;
        await this.deleteLabel({ label: this.currentLabelToDelete, issuerId: this.issuer.id });
        this.currentIssuerLabels = this.currentIssuerLabels.filter(label => label !== this.currentLabelToDelete);
      } finally {
        this.$refs.issuerLabelsModal.show();
        this.loading = false;
      }
    },
    async show(issuer) {
      this.currentIssuerLabels = issuer.labels || [];
      this.issuer = issuer;
      this.$refs.issuerLabelsModal.show();
    },
    save() {
      this.updateIssuerLabels({
        issuerId: this.issuer.id,
        body: {
          labels: this.currentIssuerLabels,
        },
      }).then(() => {
        this.UPDATE_GLOBAL_ISSUER({
          issuerId: this.issuer.id, labels: this.currentIssuerLabels,
        });
      });
    },
    async createNewLabel() {
      if (this.loading) {
        return;
      }
      this.createLabelError = '';
      if (!this.newLabel.length) {
        return;
      }

      if (this.newLabel.length > 16) {
        this.createLabelError = 'Max label length is 16 characters';
      }

      this.loading = true;
      try {
        await this.createLabelForIssuer({
          issuerId: this.issuer.id,
          label: this.newLabel,
        })
          .then(() => {
            this.currentIssuerLabels.push(this.newLabel);
          });
      } finally {
        this.loading = false;
        this.newLabel = '';
      }
    },
    openConfirmModal(label) {
      this.currentLabelToDelete = label;
      this.$refs.issuerLabelsModal.hide();
      this.$refs.confirmDeleteModal.show();
    },
    deleteLabelCanceled() {
      this.$refs.issuerLabelsModal.show();
    },
  },
};
</script>
<style lang="scss">
  .label-model-overwrite{
    .modal-header{
      .close {
        top: 30px;
      }
    }
  }
    .cp-labels-list {
        max-height: 200px;
        overflow: auto;
    }
  .create-wrap {
    width: 28%;
    display: inline-block;
    vertical-align: top;
  }
  .label-input-wrap {
    width: 70%;
    display: inline-block;
  }
  .button-reset {
    height: 38px;
    margin-top: 0;
  }
  .cp-custom-label {
    display: flex;
    justify-content: space-between;
    width: 208px;
  }
  .label {
    font-size: 16px;
    color: #9EA2A8;
    line-height: 20px;
  }
  .confirm-delete-title{
    color: #677C8C;
    font-size: 16px;
    font-weight: 500;
  }
</style>
