<template>
  <multiselect
    ref="vueSelect"
    class="sec-id-multiselect"
    :class="{ 'is-active': selected.length}"
    :options="options"
    :multiple="true"
    :clear-on-select="false"
    :placeholder="placeholder"
    track-by="value"
    label="text"
    :searchable="searchable"
    :close-on-select="false"
    :show-labels="false"
    @select="onChange"
  >
    <template
      slot="tag"
    >
      {{ null }}
    </template>
    <template
      slot="placeholder"
    >
      {{ !selected.length ? placeholder : null }}
    </template>
    <template
      slot="selection"
      slot-scope="{ search, isOpen }"
    >
      <p
        v-if="selected.length && !isOpen"
        class="d-flex align-items-center mb-0"
      >
        {{ placeholder }}
        <label :class="`${badgeClass ? badgeClass : 'selected-items-count'}`">
          {{ selected.length }}
        </label>
      </p>
    </template>
    <template
      slot="option"
      slot-scope="{ option }"
    >
      <div
        class="d-flex align-items-center"
      >
        <b-form-checkbox
          disabled
          :checked="findValue(option)"
        />
        <span class="option__title">
          {{ option.i18nKey ? $t(option.i18nKey) : option.text ? option.text : option.$groupLabel }}
        </span>
      </div>
    </template>
    <template slot="afterList">
      <div class="actions-section">
        <hr class="m-2">
        <div class="d-flex align-items-center justify-content-between action-buttons-container mb-2">
          <b-btn
            variant="link"
            class="clear-btn"
            @click="clear"
            v-text="'Clear'"
          />
          <b-btn
            variant="primary"
            @click="apply"
            v-text="'Save'"
          />
        </div>
      </div>
    </template>
    <template
      slot="noResult"
    >
      <div class="d-flex align-items-center mt-2">
        No results found.
      </div>
    </template>
    <template
      slot="noOptions"
    >
      <div class="d-flex align-items-center mt-2">
        No options available.
      </div>
    </template>
  </multiselect>
</template>

<script>

import Multiselect from 'vue-multiselect';

export default {
  name: 'SecIdMultiSelect',
  components: {
    Multiselect,
  },
  props: {
    options: {
      type: Array,
      required: true,
    },
    name: {
      type: String,
      required: true,
    },
    placeholder: {
      type: String,
      required: true,
    },
    searchable: {
      type: Boolean,
      default: false,
    },
    defaultSelected: {
      type: Array,
      default: () => [],
    },
    badgeClass: {
      type: String,
      required: false,
      default: '',
    },
  },
  data() {
    return {
      selected: [],
    };
  },
  watch: {
    defaultSelected(newVal) {
      this.selected = newVal || [];
    },
  },
  created() {
    this.selected = [...this.defaultSelected];
  },
  methods: {
    findValue(option) {
      return this.selected.some(({ value }) => value === option.value);
    },
    onChange(option) {
      const isSelected = this.selected.some(item => item.value === option.value);
      if (option.value === 'all') {
        this.selected = isSelected
          // If has selected none,preserve it, remove the rest
          ? [...this.selected.filter(item => item.value === 'none')]
          // If has selected more options than none, preserve just none and then add all available but none to avoid duplication
          : [...this.selected.filter(item => item.value === 'none'),
            // More than 100 breaks api because of headers length
            ...this.options.filter(item => item.value !== 'none')];
      } else {
        this.selected = isSelected
          ? this.selected.filter(item => item.value !== option.value)
          : [option, ...this.selected];
      }
    },
    clear() {
      this.selected = [];
      this.apply();
    },
    apply() {
      this.$refs.vueSelect.isOpen = false;
      this.selected = this.selected.map(filter => ({ ...filter, applied: true }));
      this.$emit('filtersChanged', {
        name: this.name,
        filters: this.selected.filter(item => item.value !== 'all'),
      });
    },
    deleteFilter(value) {
      this.selected = [...this.selected.filter(filter => filter.value !== value && filter.value !== 'all')];
      this.apply();
    },
  },
};
</script>

<style src="vue-multiselect/dist/vue-multiselect.min.css"/>
<style lang="scss">
  .sec-id-multiselect {
    .is-active {
      border-radius: 3px;
      border: solid 1px #617d8d;
      background-color: #ffffff;
    }

    .actions-section {
      position: sticky;
      background: #FFF;
      bottom: 0;
      padding-bottom: 0.2em;
      .action-buttons-container {
        margin: 0 12px;
      }
    }

    .multiselect__option--selected, .multiselect__option--highlight, .multiselect__option--selected.multiselect__option--highlight {
      background: #FFF !important;
      color: #35495e !important;;
      font-weight: unset !important;;
    }

    .multiselect__content-wrapper {
      margin-top: 0.5em;
      border: 1px solid #e5e5e5 !important;

      .multiselect__content {
        max-width: 100%;

        .clear-btn, .clear-btn:hover {
          text-decoration: underline;
        }

        .clear-btn {
          color: #c4c4c4;
          padding-left: 0;
        }
      }

      .option {
        &__title {
          white-space: nowrap;
          overflow: hidden;
          text-overflow: ellipsis;
        }
      }
    }

    .multiselect__tags {
      display: flex !important;
      align-items: center !important;
      padding-top: 0 !important;
      justify-content: center;

      .selected-items-count {
        background: #617d8d;
        padding: 0.1em 0.3em;
        margin: 0 0 0 0.5em;
        color: #FFF;
        font-weight: 500;
        font-size: 14px;
      }

      input {
        margin-bottom: 0;
      }
    }

    .multiselect__placeholder {
      margin-bottom: 0 !important;
    }
  }
</style>
