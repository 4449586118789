<template>
  <b-card-body class="pt-0 pb-3">
    <div class="row">
      <div class="col-sm text-sm-left text-center pt-3">
        <span
          v-if="totalItems"
          class="text-muted"
        >
          {{ $t('component.cpTablePagination.label.entries', { shownItems, totalItems }) }}
        </span>
      </div>
      <div class="col-sm pt-3">
        <b-pagination
          v-if="totalItems"
          v-model="currentPage"
          class="justify-content-center justify-content-sm-end m-0"
          :total-rows="totalItems"
          hide-goto-end-buttons
          :prev-text="$t('component.cpTablePagination.label.previous')"
          :next-text="$t('component.cpTablePagination.label.next')"
          :per-page="perPage"
          size="sm"
          @input="$emit('onInputPage', currentPage)"
          @change="$emit('onChangePage', $event)"
        />
      </div>
    </div>
  </b-card-body>
</template>

<script>
export default {
  name: 'CpTablePagination',
  props: {
    totalItems: {
      type: Number,
      required: true,
    },
    perPage: {
      type: Number,
      required: true,
    },
    activePage: {
      type: Number,
      default: null,
    },
  },
  data() {
    return {
      currentPage: 1,
    };
  },
  computed: {
    totalPages() {
      return Math.ceil(this.totalItems / this.perPage);
    },
    shownItems() {
      let lastPageItemIndex = this.currentPage * this.perPage;
      const firstPageItemIndex = lastPageItemIndex - (this.perPage - 1);
      if (this.currentPage === this.totalPages) {
        lastPageItemIndex = this.totalItems;
      }
      return `${firstPageItemIndex} - ${lastPageItemIndex}`;
    },
  },
  watch: {
    activePage(page) {
      this.currentPage = page || 1;
    },
  },
  created() {
    const { page: currentPage } = this.$route.query;
    if (currentPage) {
      this.currentPage = currentPage;
    }
  },
};
</script>
